<template>
  <div class="">
    <center-title title="额度冻结/解冻申请记录" :bottom="15"></center-title>
    <el-form ref="form" :inline="true" label-width="0px">
      <el-form-item>
        <el-button
          type="primary"
          class="whtie submit"
          @click="(dialogVisible = true), (data.applyType = 'freeze')"
        >
          申请冻结</el-button
        >
        <el-button
          type="primary"
          class="whtie submit"
          @click="(dialogVisible = true), (data.applyType = 'thaw')"
        >
          申请解冻</el-button
        >
      </el-form-item>
    </el-form>
    <el-table :data="recordLists">
      <el-table-column
        align="center"
        prop="applyTypeText"
        label="申请类型"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="applyNickname"
        label="申请人"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="createTime"
        label="申请时间"
        width="200"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="auditStatusText"
        label="审批状态"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="earlyAuditManager"
        label="初审人"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="earlyAuditReject"
        label="初审意见"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="earlyAuditTime"
        label="初审时间"
        width="200"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="finalAuditManager"
        label="终审人"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="finalAuditReject"
        label="终审意见"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="finalAuditTime"
        label="终审时间"
        width="200"
      >
      </el-table-column>
    </el-table>
    <el-dialog title="上传附件" :visible.sync="dialogVisible" width="30%">
      <el-upload
        :action="`${download}/file/formimage`"
        :headers="{ token: $store.getters.userInfo.token }"
        :on-success="successUpload"
        :on-exceed="handleExceed"
        :show-file-list="true"
        :limit="1"
        :on-remove="handleRemove"
      >
        <el-button size="small" type="primary" class="white"
          >点击上传</el-button
        >
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setRmOperate()">提交</el-button>
      </span>
    </el-dialog>
    <div class="row-center pagination mt30">
      <make-pagination
        @resetList="resetList"
        :orderObject="recordObject"
      ></make-pagination>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import MakePagination from "@/components/MakePagination";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
    "make-pagination": MakePagination,
  },
  data() {
    return {
      recordLists: [],
      recordObject: {},
      page_no: 1,
      page_size: 10,
      dialogVisible: false,
      download: process.env.VUE_APP_DOWNLOAD_URL,
      data: {
        applyType: "",
        file: "",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    getApplyChangeStatusRecordLists() {
      this.$api
        .getApplyChangeStatusRecordListsApi({
          page_no: this.page_no,
          page_size: this.page_size,
        })
        .then((res) => {
          this.recordLists = res.data.lists;
          this.recordObject = res.data;
        });
    },
    resetList(e) {
      this.page_no = e;
      this.getApplyChangeStatusRecordLists();
    },
    handleRemove(file, fileList) {
      this.data.file = "";
    },
    successUpload(res) {
      let { code, data } = res;
      this.data.file = res.data.uri;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制上传 1 个文件`);
    },
    setRmOperate() {
      this.$api.applyChangeStatusApi(this.data).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.resetList(1);
          this.dialogVisible = false;
        }
      });
    },
  },
  created() {
    this.getApplyChangeStatusRecordLists();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 0px 20px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>
